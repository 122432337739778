$color-plum: #7B3081;
$color-plum-dark: #45004D;
$color-red-dark: #8A2235;
$color-red-invalid: #dc3545;
$color-green-dark: #007E4C;
$color-blue-night: #032830;
$color-blue-ocean: #0E5168;
$color-grey-blue: #A7B8C4;
$color-body: #F5F5F5;
$color-silver-light: #F2F5FA;
$color-silver-dark: #BAC0C4;
$color-grey: #DCDFE6;
$color-white: #FFF;
$color-black: #000;
$color-blue-info: #CBE6f6;
