.cgu-container {
  padding-top: 10%;
  display: flex;
  justify-content: center;

  &-content {
    width: 100%;
    max-width: 600px;
  }
}
