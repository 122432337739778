.button-container {
  display: grid;
  grid-template-columns: 30% 70%;
  border: 1px solid $color-blue-ocean;
  border-radius: 4px;
  padding: 6px;

  .text-container {
    p {
      line-height: 1.2rem;
      margin: 0;
      font-size: 14px;
      &.store-text {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }

  .logo-container {
    display: grid;
    place-items: center;


    img {
      width: 90%;
      padding-right: 5px;
    }
  }

  &:disabled {
    border-color: $color-silver-dark;

    .text-container {
      color: $color-silver-dark;
    }
  }
}
