.edit-button {
  background: transparent;
  border: 0;
  padding: 0;
}

.field-label {
  
  &-input {
    min-width: 255px;
    color: $color-grey-blue;
    background-color: $color-silver-light;
  }
}

.parameter-container {
  display: flex;
  justify-content: center;

  &-content {
    width: 100%;
    max-width: 576px;
  }
}