@import '../colors';

.border-btn {
  border-color: $color-grey-blue;
  &:hover {
  border-color: $color-grey-blue;
  }
  &:focus {
    border-color: $color-grey-blue;
  }
}
.dropdown {
  #medelse-dropdown {
    display: block;
    width: 100%;
    border: 1px solid $color-grey-blue;
    border-radius: $border-radius;
    padding: 6px 9px;
    color: $color-blue-night;
    text-align: left;
    background-color: $color-white;

    &::after {
      position: absolute;
      top: 17px;
      right: 25px;
    }

    &-cities {
      text-align: left;

      &::after {
        position: absolute;
        top: 17px;
        right: 25px;
      }
    }
  }

  #medelse-dropdown + .dropdown-menu {
    min-width: 100%;
    max-height: 200px;
    overflow-y: scroll;
  }

  .custom-medelse-dropdown {
    text-align: left;
    background-color: $color-white;
    .dropdown-menu {
      min-width: 100%;
      height: 300px;
      overflow: hidden;
    }
  }
}

.other-specialties {
  .dropdown-toggle::after {
    position: absolute;
    top: 17px;
    right: 8px;
  }
  .dropdown-other-specialties {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

#phone-code-dropdown {
  color: $color-blue-night;
  background-color: white;
  border: 1px solid $color-grey-blue;
}

.text-field {
  display: block;
  width: 100%;
  border: 1px solid $color-grey-blue;
  border-radius: $border-radius;
  padding: 6px 9px;
  color: $color-blue-night;
  &-disabled {
    display: block;
    width: 100%;
    border: 1px solid $color-grey-blue;
    border-radius: $border-radius;
    padding: 6px 9px;
    color: $color-grey-blue;
    background-color: $color-silver-light;
  }

  &-label {
    display: block;
    width: 100%;
    font-family: $font-family-base;
    font-weight: $font-medium;
    & span {
      color: $color-black;
    }
  }
  &-phone {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
  }
  &-phone-second {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.phone-container {
  display: grid;
  grid-template-columns: 65px 1fr;
}

.input-group-text {
  background-color: transparent;
  border: none;
}


.file-input-name {
    color: $color-grey-blue;
}
