#download-app-view {
  .display-1 {
    font-size: 16px !important;

    @media only screen and (min-width: 1200px) {
      font-size: 36px !important;
    }
  }

  .card {
    background-position: right bottom, right bottom, right bottom;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 100%, 0%, 100%;

    @media only screen and (min-width: 1200px) {
      background-size: 60%, 50%, 0%;
    }
  }
}
