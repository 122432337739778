@import 'colors';

$primary: $color-plum;
$primary-dark: $color-plum;
$secondary: $color-blue-ocean;
$tertiary: $color-silver-dark;
$success: $color-green-dark;
$danger: $color-red-dark;

$theme-colors: (
  'primary':      $primary,
  "secondary":    $color-blue-ocean,
  "tertiary":      $color-silver-dark,
  "success":      $color-green-dark,
  "danger":       $color-red-dark,
  "invalid":      $color-red-invalid,
  "dark":         $color-blue-night,
);

$body-bg: $color-silver-light;
$body-color: $color-blue-night;

$font-bold: 700;
$font-medium: 400;
$font-family-base: 'Open Sans', sans-serif;
$font-size-root: 14px;
$headings-color: $color-plum-dark;
$card-color: $color-blue-night;
$card-title-color: $color-plum-dark;

.modal-body h2 {
  color: $color-plum-dark;
}

$card-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%) !default;

$grid-gutter-width:           15px !default;

$border-radius:               5px !default;

$spacing-small:               20px !default;
$spacing-medium:              25px !default;

$table-cell-vertical-align:   middle !default;
$table-color:                 $body-color !default;
$table-bg:                    $color-silver-light !default;
$table-striped-color:         $color-silver-light !default;
$table-striped-bg:            $color-white !default;
$table-border-color:          $color-grey !default;
$table-variants: (
  "primary":    $primary,
  "secondary":  $color-blue-ocean,
) !default;

$dropdown-link-active-bg: $color-grey;

@import "~bootstrap/scss/bootstrap";

@import 'navbar';
@import 'table';
@import 'components/download-app-view';
@import 'components/download-app-button';
@import 'components/button';
@import 'components/card-title';
@import 'components/form-actions';
@import 'components/text-field';
@import 'components/toast';
@import 'components/parameters';
@import "components/alert";
@import 'components/cgu';

@import "pages/register";
@import "pages/login";
