.toast {
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  font-weight: $font-bold;
  width: 100%;
  padding: 5px;

  &-success {
    background-color: #CBF6E5;
  }

  &-warning {
    background-color: #FDE6D9;
  }

  &-danger {
    background-color: #FAD7DD;
  }
}


.header {
  flex: 1;
  background-color: transparent;
  font-size: 16px;

  &-success {
    color: #007E4C
  }

  &-warning {
    color: #FAA07F;
  }

  &-danger {
    color: #8A2235;
  }
}

.icon-container {
  width: 35px;
  height: 35px;
  background-color: $color-white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 10px;

  &-success {
    border: 4px solid #007E4C;
  }

  &-warning {
    border: 4px solid #FAA07F;
  }

  &-danger {
    border: 4px solid #8A2235;
  }
}
