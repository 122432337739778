nav.navbar-light {
  #main-navbar-dropdown {
    color: $primary;
  }

  .dropdown-menu {
    border-color: $primary;
    padding: 0;

    &.dropdown-menu[data-bs-popper] {
      position: absolute;
      right: 0;
      left: auto;
    }

    .dropdown-item {
      color: $primary;
      padding: 10px;
      min-width: 165px; /* Prevent menu width changes on  item hover (due to font-weight change) */

      &:hover {
        font-weight: 600;
      }

      &:hover:not(.is-red), &:active:not(.is-red), &:focus:not(.is-red) {
        background-color: $color-white;
      }

      &.is-active, &:hover.is-active {
        background-color: $primary;
        color: $color-white;
      }

      &.is-red {
        background-color: $color-red-dark;
        color: $color-white;
      }
    }
  }
}
