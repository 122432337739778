table{
  &.striped > tbody > tr:nth-of-type(odd) > * {
    background-color: $color-white;
  }

  &.striped > tbody > tr:nth-of-type(even) > * {
    background-color: $color-silver-light;
  }

  &.table.table-striped > :not(:first-child) {
    border-top: 1px solid;
  }
  div[role="presentation"] {
    position: relative;

    .svg-inline--fa {
      position: absolute;
      right: 4px;
      top: 5px;
    }
  }
}
