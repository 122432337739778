.btn-medelse {
  padding: 10px;
  color: $color-white;

  &:hover {
    color: $color-white;
  }
}

.btn-negative {
  background-color: $color-red-dark;

  &:hover {
    background-color: $color-red-dark;
    color: $color-white;
  }
}
